import React from "react";
import classes from "./Footer.module.css";
import Button from "./Button";
const Footer: React.FC = () => {
  return (
    <footer className={classes.footer}>
      <Button path="/cgu" label="CGU"></Button>
    </footer>
  );
};

export default Footer;
