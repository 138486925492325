import React from "react";
import classes from "./Play.module.css";
import { useState } from "react";
import Header from "../components/Header";
import { sendGameRequest } from "../utils/sendGames";
const Play: React.FC = () => {
  const [gameTextInput, setGameTextInput] = useState<string>("");
  const [gameAmountInput, setgameAmountInput] = useState<string>("0.5");
  const [usernameInput, setUsernameInput] = useState<string>("");
  const [emailInput, setEmailInput] = useState<string>("");

  const gameTextChangeHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setGameTextInput(event.target.value);
  };

  const gameAmountChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setgameAmountInput(event.target.value);
  };

  const usernameChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUsernameInput(event.target.value);
  };
  const emailChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInput(event.target.value);
  };
  const handleReset = () => {
    console.log("handle reset");
    setGameTextInput("");
    setgameAmountInput("0.5");
  };

  async function submitFormHandler(event: React.FormEvent) {
    event.preventDefault();

    console.log(
      `handles form submit :\n game amount ${gameAmountInput}\n game text :${gameTextInput}`
    );
    const response = await sendGameRequest(
      gameTextInput,
      gameAmountInput,
      usernameInput,
      emailInput
    );
    // ERROR HANDLING TODO

    console.log("response:  payement" + JSON.stringify(response));
    window.location.replace(response);
  }

  return (
    <>
      <Header />

      <div className={classes.play_body}>
        <form onSubmit={submitFormHandler}>
          <fieldset>
            <label htmlFor="gameText">Game Text</label>
            <textarea
              rows={6}
              cols={30}
              required
              minLength={1}
              name="gameText"
              value={gameTextInput}
              onChange={gameTextChangeHandler}
              spellCheck="false"
              autoCorrect="off"
            ></textarea>
            <div className={classes.flex_container}>
              <div className={classes.input_and_label}>
                <label htmlFor="username">Username</label>
                <div className={classes.input_wrapper}>
                  <div>
                    <input
                      required
                      className={classes.amount_input}
                      type={"text"}
                      value={usernameInput}
                      onChange={usernameChangeHandler}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.input_and_label}>
                <label htmlFor="email">Email</label>
                <div className={classes.input_wrapper}>
                  <div>
                    <input
                      required
                      className={classes.amount_input}
                      type={"email"}
                      value={emailInput}
                      onChange={emailChangeHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
            <label htmlFor="amount">Score</label>
            <div className={classes.input_wrapper}>
              <div>
                <input
                  required
                  className={classes.amount_input}
                  type={"number"}
                  step=".01"
                  min={"0.5"}
                  value={gameAmountInput}
                  onChange={gameAmountChangeHandler}
                  placeholder="999999999999.99"
                />
              </div>
              <div className={classes.suffix}>€</div>
            </div>
            <div className={classes.button_container}>
              <button type="reset" onClick={handleReset}>
                Reset
              </button>
              <button type="submit" value="Submit">
                Play !
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    </>
  );
};

export default Play;
