import React from "react";
import classes from "./Coin.module.css";
import Tooltip from "./Tooltip";

const Coin: React.FC = () => {
  return (
    <Tooltip text="This game has been the top one once">
      <div className={classes.coin_container}>
        <img
          className={classes.spin}
          src={require("../images/coin.png")}
          alt="coin"
          width="100%"
        ></img>
      </div>
    </Tooltip>
  );
};

export default Coin;
