import React from "react";
import Header from "../components/Header";
import classes from "./Cgu.module.css";

const Cgu: React.FC = () => {
  return (
    <div className={classes.page_container}>
      <Header />
      <h2>Conditions générales d'utilisation</h2>
      <p>En vigueur au 13/10/2023</p>
      <p>
        Les présentes conditions générales d'utilisation (dites « CGU ») ont
        pour objet l'encadrement juridique des modalités de mise à disposition
        du site et des services par paytowin.fr et de définir les conditions
        d'accès et d'utilisation des services par « l'Utilisateur ».
        <br />
        Les présentes CGU sont accessibles sur le site à la rubrique «CGU»
      </p>
      <h2>Article 1 : Les mentions légales</h2>
      <p>
        L'édition du site paytowin.fr est assurée par la Société Autoentreprise
        Mirai au capital de 0 euros, immatriculée au RCS de Paris sous le numéro
        951893007, dont le siège social est situé au 23 rue François Bonvin
        Adresse e-mail : ptwadmin@proton.me L'hébergeur du site paytowin.fr est
        la société Vercel
      </p>
      <h2>ARTICLE 2 : Accès au site</h2>
      <p>
        Le site paytowin.fr permet à l'Utilisateur un accès gratuit aux services
        suivants : Le site internet propose les services suivants : Publication
        de texte en ligne dont l'affichage est trié de manière descendante en
        fonction du prix que l'utilisateur a choisi de payer pour le publier.
        Plus l'utilisateur paye cher plus son texte est en haute de la liste. Le
        site est accessible gratuitement en tout lieu à tout Utilisateur ayant
        un accès à Internet. Tous les frais supportés par l'Utilisateur pour
        accéder au service (matériel informatique, logiciels, connexion
        Internet, etc.) sont à sa charge.
      </p>
      <h2>ARTICLE 3 : Collecte des données</h2>
      <p>
        Le site ne collecte aucune donnée à caractère personnel concernant
        l'Utilisateur.
      </p>
      <h2>ARTICLE 4 : Propriété intellectuelle</h2>
      <p>
        Les marques, logos, signes ainsi que tous les contenus du site (textes,
        images, son…) font l'objet d'une protection par le Code de la propriété
        intellectuelle et plus particulièrement par le droit d'auteur.
        L'Utilisateur doit solliciter l'autorisation préalable du site pour
        toute reproduction, publication, copie des différents contenus. Il
        s'engage à une utilisation des contenus du site dans un cadre
        strictement privé, toute utilisation à des fins commerciales et
        publicitaires est strictement interdite. Toute représentation totale ou
        partielle de ce site par quelque procédé que ce soit, sans
        l'autorisation expresse de l'exploitant du site Internet constituerait
        une contrefaçon sanctionnée par l'article L 335-2 et suivants du Code de
        la propriété intellectuelle. Il est rappelé conformément à l'article
        L122-5 du Code de propriété intellectuelle que l'Utilisateur qui
        reproduit, copie ou publie le contenu protégé doit citer l'auteur et sa
        source.{" "}
      </p>
      <h2>ARTICLE 5 : Responsabilité</h2>
      <p>
        Les informations communiquées sont présentées à titre indicatif et
        général sans valeur contractuelle. Malgré des mises à jour régulières,
        le site paytowin.fr ne peut être tenu responsable de la modification des
        dispositions administratives et juridiques survenant après la
        publication. De même, le site ne peut être tenue responsable de
        l'utilisation et de l'interprétation de l'information contenue dans ce
        site. Le site paytowin.fr ne peut être tenu pour responsable d'éventuels
        virus qui pourraient infecter l'ordinateur ou tout matériel informatique
        de l'Internaute, suite à une utilisation, à l'accès, ou au
        téléchargement provenant de ce site. La responsabilité du site ne peut
        être engagée en cas de force majeure ou du fait imprévisible et
        insurmontable d'un tiers.
      </p>
      <h2>ARTICLE 6 : Liens hypertextes</h2>
      <p>
        Des liens hypertextes peuvent être présents sur le site. L'Utilisateur
        est informé qu'en cliquant sur ces liens, il sortira du site
        paytowin.fr. Ce dernier n'a pas de contrôle sur les pages web sur
        lesquelles aboutissent ces liens et ne saurait, en aucun cas, être
        responsable de leur contenu.
      </p>
      <h2>ARTICLE 7 : Cookies</h2>
      <p>
        L'Utilisateur est informé que lors de ses visites sur le site, un cookie
        peut s'installer automatiquement sur son logiciel de navigation. Les
        cookies sont de petits fichiers stockés temporairement sur le disque dur
        de l'ordinateur de l'Utilisateur par votre navigateur et qui sont
        nécessaires à l'utilisation du site paytowin.fr. Les cookies ne
        contiennent pas d'information personnelle et ne peuvent pas être
        utilisés pour identifier quelqu'un. Un cookie contient un identifiant
        unique, généré aléatoirement et donc anonyme. Certains cookies expirent
        à la fin de la visite de l'Utilisateur, d'autres restent. L'information
        contenue dans les cookies est utilisée pour améliorer le site
        paytowin.fr. En naviguant sur le site, L'Utilisateur les accepte.
      </p>
      <h2> ARTICLE 8 : Publication par l'Utilisateur</h2>
      <p>
        Le site permet aux membres de publier les contenus suivants : texte.
        Dans ses publications, le membre s'engage à respecter les règles de la
        Netiquette (règles de bonne conduite de l'internet) et les règles de
        droit en vigueur. Le site peut exercer une modération sur les
        publications et se réserve le droit de refuser leur mise en ligne, sans
        avoir à s'en justifier auprès du membre. Le membre reste titulaire de
        l'intégralité de ses droits de propriété intellectuelle. Mais en
        publiant une publication sur le site, il cède à la société éditrice le
        droit non exclusif et gratuit de représenter, reproduire, adapter,
        modifier, diffuser et distribuer sa publication, directement ou par un
        tiers autorisé, dans le monde entier, sur tout support (numérique ou
        physique), pour la durée de la propriété intellectuelle. Le Membre cède
        notamment le droit d'utiliser sa publication sur internet et sur les
        réseaux de téléphonie mobile. La société éditrice s'engage à faire
        figurer le nom du membre à proximité de chaque utilisation de sa
        publication. Tout contenu mis en ligne par l'Utilisateur est de sa seule
        responsabilité. L'Utilisateur s'engage à ne pas mettre en ligne de
        contenus pouvant porter atteinte aux intérêts de tierces personnes. Tout
        recours en justice engagé par un tiers lésé contre le site sera pris en
        charge par l'Utilisateur. Le contenu de l'Utilisateur peut être à tout
        moment et pour n'importe quelle raison supprimé ou modifié par le site,
        sans préavis.
      </p>
      <h2>ARTICLE 9 : Droit applicable et juridiction compétente</h2>
      <p>
        La législation française s'applique au présent contrat. En cas d'absence
        de résolution amiable d'un litige né entre les parties, les tribunaux
        français seront seuls compétents pour en connaître. Pour toute question
        relative à l'application des présentes CGU, vous pouvez joindre
        l'éditeur aux coordonnées inscrites à l'ARTICLE 1.
      </p>
    </div>
  );
};

export default Cgu;
