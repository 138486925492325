import React from "react";
import classes from "./Button.module.css";
import { Link } from "react-router-dom";
interface Props {
  path: string;
  label: string;
}
const Button: React.FC<Props> = ({ path, label }) => {
  return (
    <div className={classes.link_container}>
      <Link className={classes.link} to={path}>
        {label}
      </Link>
    </div>
  );
};

export default Button;
