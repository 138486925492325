import React from "react";
import classes from "./GameDisplay.module.css";
import { Game } from "../utils/types";
import Coin from "./Coin";

export interface Props {
  game: Game;
  rank: number;
}
const GamesDisplay: React.FC<Props> = ({ game, rank }) => {
  return (
    <div className={classes.game_container}>
      <div className={classes.game_start}>
        <div className={`${classes.rank} ${classes.game_item}`}>{rank}</div>
        <div className={`${classes.username} ${classes.game_item}`}>
          {game.username}
        </div>
      </div>
      <div className={classes.game_middle}>
        <p className={`${classes.gametext} ${classes.game_item}`}>
          {game.text}{" "}
        </p>
      </div>
      <div className={classes.game_end}>
        <div className={`${classes.medals} ${classes.game_item}`}>
          {game.has_ranked_one ? <Coin></Coin> : ""}
        </div>
        <div className={`${classes.date} ${classes.game_item}`}>
          {new Date(game.created_at).toLocaleString("fr-FR", {
            timeZone: "Europe/Paris",
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          })}
        </div>
      </div>
    </div>
  );
};

export default GamesDisplay;
