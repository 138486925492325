import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Play from "./Pages/Play";
import React from "react";
import Cgu from "./Pages/Cgu";

function App(): React.ReactNode {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/play" element={<Play />} />
      <Route path="/cgu" element={<Cgu />} />
    </Routes>
  );
}

export default App;
