import React, { useCallback, useEffect, useState } from "react";
import Header from "../components/Header";
import GamesDisplay from "../components/GameDisplay";
import classes from "./Home.module.css";
import { Game } from "../utils/types";
import getGames from "../utils/getGames";
import Button from "../components/Button";
import Footer from "../components/Footer";

const Home: React.FC = () => {
  const [games, setGames] = useState<Array<Game>>([]);

  const getGamesCallback = useCallback(async () => {
    try {
      const games = await getGames();
      setGames(games);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getGamesCallback();
  }, [getGamesCallback]);

  return (
    <>
      <Header />
      <div className={classes.games_container}>
        <Button path="/Play" label="Play" />
        {games.length > 0 &&
          games.map((game, index) => {
            return (
              <GamesDisplay
                key={game.created_at}
                rank={index + 1}
                game={game}
              ></GamesDisplay>
            );
          })}
      </div>
      <Footer />
    </>
  );
};

export default Home;
