import React from "react";
import classes from "./Tooltip.module.css";

interface Props {
  text: string;
  children: React.ReactNode;
}
const Tooltip: React.FC<Props> = ({ text, children }) => {
  return (
    <div className={classes.container}>
      <div className={classes.tooltip}>{text}</div>
      <div className={classes.children}>{children}</div>
    </div>
  );
};
export default Tooltip;
