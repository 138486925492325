import { api_url } from "./constants";
import { Game } from "./types";

const buildGetGamesData = (): RequestInit => {
  return {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      host: `${api_url}`,
    },
    credentials: "omit",
    keepalive: true,
  };
};

async function getGames(): Promise<Game[]> {
  const data = buildGetGamesData();
  return fetch(`${api_url}/games`, data).then((response) => {
    return response
      .json()
      .then((data: Game[]) => {
        console.log(data);
        return data;
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
  });
}

export default getGames;
