import { api_url } from "./constants";

const buildSendGameRequestData = (
  gameTextInput: string,
  gameAmountInput: string,
  gameUsernameInput: string,
  gameEmailInput: string
): RequestInit => {
  return {
    method: "post",
    keepalive: true,
    headers: {
      "Content-Type": "application/json",
      host: "localhost:3000",
    },
    credentials: "omit",
    body: JSON.stringify({
      game: {
        text: gameTextInput,
        amount: gameAmountInput,
        username: gameUsernameInput,
        email: gameEmailInput,
      },
    }),
  };
};

export async function sendGameRequest(
  gameTextInput: string,
  gameAmountInput: string,
  gameUsernameInput: string,
  gameEmailInput: string
) {
  const data = buildSendGameRequestData(
    gameTextInput,
    gameAmountInput,
    gameUsernameInput,
    gameEmailInput
  );
  const response = await fetch(`${api_url}/payment`, data).then((response) => {
    return response
      .json()
      .then((data) => {
        console.log("payment response : " + JSON.stringify(data));
        return data;
      })
      .catch((err) => console.log(err));
  });
  return response;
}
