import React, { useCallback } from "react";
import classes from "./Header.module.css";
import { Link } from "react-router-dom";
import Button from "./Button";

const Header: React.FC = () => {
  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <header className={classes.header}>
      <div className={classes["header-title"]}>
        <Link onClick={scrollUp} className={classes.link} to="/">
          <h1>{"Pay to Win !"}</h1>
        </Link>
      </div>
      <Button path="/Play" label="Play" />
    </header>
  );
};

export default Header;
